import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import AOS from 'aos';
import "aos/dist/aos.css";
import Helmet from "react-helmet"

const AmazonPaidPage = ({location}) => {

    useEffect(() => {
        AOS.init({
            once:true
        });
      }, []);

  return (
    <Layout location={location}>
         <Helmet>
            <title>Amazon Business Integration | Vantage Circle</title>

            <meta name="description" content="Give your employees freedom to choose from 10M+ products on Amazon with our Amazon business integration. Try it today!" />
            <meta property="twitter:title" content="Amazon Business Integration | Vantage Circle" />
            <meta name= "robots" content= "index" />
                    
            <meta property="twitter:description" content="Give your employees freedom to choose from 10M+ products on Amazon with our Amazon business integration. Try it today!" />
                    
            <meta property="og:type" content="website"/>
                    
            <meta property="og:url" content="https://www.vantagecircle.com/amazon-business-integration/"/>

            <meta property="og:site_name" content="Vantage Circle"/>
            <meta property="og:title" content="Amazon Business Integration | Vantage Circle" />

            <meta property="og:description" content="Give your employees freedom to choose from 10M+ products on Amazon with our Amazon business integration. Try it today!" />
            <link rel="canonical" href="https://www.vantagecircle.com/amazon-business-integration/"></link>
        </Helmet>


        <section id='watchVideoClass' className="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
            <div className="max-w-7xl flex flex-col xl:flex-row justify-center items-start z-10 lg:px-3 relative">
                <div className="w-full xl:w-8/12 flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
                    {/* <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full border border-gray-darklight bg-indigo-50">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Rewards-ico-hero.png" alt="Vantage Pulse" height="15" width="15" />
                        <h2 class="text-purple-100 text-sm lato"><span class="font-bold">&nbsp;&nbsp;VANTAGE </span>REWARDS</h2>
                    </div> */}
                     <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full bg-indigo-50">
                        {/* <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Rewards-ico-hero.png" alt="Vantage Pulse" height="15" width="15" /> */}
                        <h2 class="text-purple-100 text-sm lato">AMAZON BUSINESS INTEGRATION</h2>
                    </div>

                    <h1 className="text-purple-100 bannerSectionTitle-3 lg:pr-3 text-center xl:text-left w-full xl:w-10/12"><span className="relative"><img decoding="async" class="absolute -bottom-1 right-0 xl:right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span>Supercharge your rewards program with our <span className= "text-orange">Amazon</span> Store</h1>


                    {/* <p className="my-8 text-lg xl:text-xl text-purple-100 md:w-10/12">Transform your company's rewards and recognition program with our HR-centric Vantage Rewards Program designed for effortless automation and streamlined simplicity.</p> */}
                    <div className="xl:flex justify-center items-center lg:justify-start w-5/6 md:w-1/3 xl:w-9/12 2xl:w-2/3 cursor-pointer my-8">
                        <a className="vc-new-orange-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="#form">Get started</a>
                        <button className="youtube-video-btn vc-new-white-border-btn-rounded-full lato rounded-full mt-3 xl:mt-0 xl:ml-2 modal-open modal-toggle">Watch video</button>
                    </div>
                </div>     
                <div className='xl:w-5/12 h-80 md:h-500 xl:h-80'></div>   
                <div class="absolute -bottom-28 md:right-1/4 xl:-bottom-16 xl:right-0 flex justify-center items-center h-1/2 md:h-full mt-6 xl:mt-0 lg:ml-3" data-aos="fade-up" data-aos-duration="500" data-aos-delay= "600"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/amazon-business.png" alt="Vantage Rewards" width="500" /></div>
            </div>
        </section>

        <div class="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
            <div class="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                    <svg class="fill-current text-white" width="30" height="30" viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                    </svg>
                </button>
                <div class="modal-content text-left">
                    <div class="aspect-w-16 aspect-h-9">        
                        {/* <iframe loading="lazy" id = "myIframe" src = "" class="video" title="Vantage Circle Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe> */}
                    
                        <iframe class="video"
                            width="560"
                            height="315"
                            src=""
                            data-src="https://www.youtube.com/embed/lMrM3gOJcyQ"
                            // srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/lMrM3gOJcyQ><img src=https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/amazon-integration-thumbnailpng.png alt='amazon-business-integration'><span>▶</span></a>"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            title="Vantage Circle"
                            ></iframe>
                    </div>
                </div>   
            </div>
        </div>

        <section className='w-full flex justify-center items-center lg:pb-5 px-5 xl:px-0 overflow-hidden pb-8'>
            <div className='xl:flex lg:max-w-7xl py-5 lg:py-0'>
                <div className='xl:w-7/12 pt-10 xl:py-24 lg:pr-3'>
                    <h2 className='text-3xl lg:text-4xl xl:text-5xl font-bold xl:text-left text-center'>Give your employees a <span className='text-orange'>reason</span> to fall in love with your organization</h2>
                    <p className='pt-6 xl:py-9 text-xl text-center xl:text-left text-gray-500 md:w-10/12 xl:w-10/12 mx-auto xl:mx-0'> Let them select from 10M+ options to delight themselves for all their efforts at work combined with additional benefits that match your organizational goals.</p>  
                </div>
                <div className='xl:w-4/12 xl:py-10 grid justify-center xl:justify-end items-start relative'>
                    <div className= "grid md:flex justify-center transform -translate-x-2 my-2 xl:my-0 lg:-translate-x-0 lg:py-0 2xl:pb-16">
                        <img src='' />
                    </div>
                    <div className='grid grid-cols-2 md:grid-cols-3 gap-5 xl:gap-40 py-5 xl:py-0 2xl:py-5 xl:ml-10 z-10'>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "400">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-42.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-3 text-sm'>Save 15% on Service Awards budget</p>
                        </div>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "500">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-38.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-3 text-sm'>Cut transit time with Prime delivery</p>
                        </div>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "600">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Performance-driven-culture.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-3 text-sm'>Create a performance-driven culture</p>
                        </div>
                        <div className='xl:hidden p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "700">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-41.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Rewards catalog of 10m+ product</p>
                        </div>
                        <div className='xl:hidden p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "800">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Peer-to-peer.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Boost peer-to-peer recognition</p>
                        </div>
                        <div className='xl:hidden p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "900">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Give-back.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Give back what you save</p>
                        </div>
                    </div>

                    <div className='xl:grid grid-cols-2 xl:grid-cols-3 gap-5 xl:gap-32 py-5 xl:pt-8 2xl:py-5 hidden z-10'>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "700">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-41.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-3 text-sm'>Freedom to choose from millions of products</p>
                        </div>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "800">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Peer-to-peer.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-3 text-sm'>Boosts peer-to-peer recognition</p>
                        </div>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '12rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "900">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Give-back.png' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-3 text-sm'>Give back what you save</p>
                        </div>
                    </div>
                    <div className='absolute -top-20 -right-52 bg-purple-150 opacity-20 rounded-full z-0' style = {{ height: '600px', width: '600px' }}>
                        <img src='' />
                    </div>
                </div>
            </div>
        </section>
        {/* <section class="flex w-full place-content-center py-5 lg:py-10 bg-white overflow-x-hidden px-3 lg:px-0">
            <div class="flex flex-col place-items-center max-w-7xl px-3">
                <h2 class=".bannerSectionTitle-2-center text-3xl xl:text-5xl text-center text-gray-250 my-8 px-1">Make rewarding fun, exclusive and effortless with our Amazon Business integration</h2>
                <div class="w-full flex flex-col justify-center items-center md:my-10 py-3 md::py-6 xl:py-10">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-ful xl:my-12 relative">
                        <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start z-10">
                            <h2 class="text-gray-250 rewardsSectionTitle my-3">Access to 10M+ products available on Amazon without leaving the platform.</h2>
        
                        </div>
                        <div class="w-full flex justify-center items-center z-10">
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Group-41.png" />
                        </div>
                        <div class="z-0 hidden lg:block absolute lg:-left-20 lg:-bottom-16 2xl:-left-28">
                            <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-5.png" />
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row-reverse justify-center items-start w-full my-12 relative">
                        <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                            <h2 class="text-gray-250 rewardsSectionTitle my-3">Amazon Prime delivery services for all orders without prime membership.</h2>
                        </div>
                        <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1657706836/gatsbycms/uploads/2022/07/Rewards_Occastion.png" />
                        </div>
                        <div class="z-0 hidden lg:block absolute lg:-right-20 lg:-bottom-12 2xl:-right-28">
                            <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-4.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <section className='py-5'>
            <div className='max-w-7xl mx-auto px-5'>
                <div className='grid xl:grid-cols-3 gap-5 xl:gap-16 xl:mt-10 xl:mb-10'>
                    <div className='my-5 border-purple-150 border rounded-xl p-6 flex-col justify-center items-center xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "300">
                        <h2 className='py-2 text-2xl text-center'>Frequent catalog refreshment with the newest/latest products.</h2>
                    </div>
                    <div className='my-5 xl:my-0 border-purple-150 border rounded-xl p-6 flex items-center justify-center' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "400">
                        <h2 className='py-2 text-2xl text-center'>Get access to discounts on Amazon.</h2>
                    </div>
                    <div className='my-5 xl:my-0 border-purple-150 border rounded-xl p-6' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "500">
                        <h2 className='py-2 text-2xl text-center'>Equal privilege and experience to everyone independent location.</h2>
                    </div>
                </div>
            </div>
        </section>   */}
        <section class="w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-8 overflow-y-hidden rewards-bg-1 px-3 lg:px-0" id="FormVR">
            <div class="lg:flex max-w-7xl px-3">
                <div class="card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-2/3">
                    <h2 class="text-3xl xl:text-5xl text-center xl:text-left text-gray-250 font-bold pt-5 mb-6 md:px-12 lg:px-0 xl:w-10/12">Prime your benefits</h2>
                    <p class="mb-8 text-center text-xl md:text-left mt-0 mb-4 xl:w-8/12 text-gray-500">
                    Unlock the Amazon advantage with our Rewards & Recognition platform.
                    </p>
                    <div className='mt-12 mb-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Rewards-Catalog.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Freedom and flexibility to curate a digital Rewards Catalog</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/AUtomated-recognition.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Craft a custom Milestone Catalog for Service Awards</p>
                        </div>
                    </div>
                    {/* <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Announcements.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Company wide announcements</p>
                        </div>
                    </div> */}
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/dashboard-interface-copy-4.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Intuitive Admin Dashboard for complete control</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Rewards-Catalog.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Automated Rewards and Recognition</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Awards-Badges.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Customized awards & badges</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/15-Languages.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Available in 16+ languages</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/CUstomer-support.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Best-in-class customer support</p>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex items-center'>
                    <div id="form" className="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 flex flex-col place-items-center rounded-lg px-6 py-10 rewards-bg-2">
                        <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">
                            Schedule a demo
                        </h2>
                        {/* <p class="text-base mb-8 text-center mt-2">
                            Get a personalized walkthrough of Vantage Rewards from a friendly member of our team
                        </p> */}
                        <div class="w-full flex justify-center items-center lato rewards team">
                            <HubspotForm
                            portalId="6153052"
                            formId="8dd71346-84d4-43c7-bd98-0223464fb47f"
                            onSubmit={() => console.log("Submit!")}
                            onReady={form => console.log("Form ready!")}
                            loading={<div>Loading...</div>}
                            />
                        </div>
                        <p class="text-sm text-center">
                            We safeguard your{" "}
                            <a href="/privacy-policy/" class="text-orange">
                            Privacy
                            </a>
                        </p>
                        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                    </div>
                </div>
            </div>
        </section>
        <section className='py-5'>
            <div className='max-w-7xl mx-auto px-5'>
                <h2 className='text-gray-250 text-3xl xl:text-5xl max-w-3xl mx-auto mt-10 mb-5 font-bold text-center'>Retain top talent with our exclusive personalized <span className='text-orange'>features</span></h2>
                <div className='grid xl:grid-cols-3 gap-5 xl:gap-16 xl:mt-20 xl:mb-10'>
                    <div className='my-5 flex-col justify-center items-center xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "300">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-15-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Instant recognition</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Experience the power of instant recognition with our innovative platform.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "400">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-19-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Culture of appreciation</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Cultivate a culture of appreciation at your company.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "500">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-14-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Long service award</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Recognize individuals' substantial contributions over time.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "600">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-11-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Personalized awards</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Improve employee engagement and productivity with our innovative employee award programs.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "700">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-8.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Rewards redemption</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>With multiple redemption options and a user-friendly platform, you can claim your rewards quickly and effortlessly.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "800">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-7-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Super quick integration</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Seamless integrations with your existing HCM/HRIS platform and single-sign-on (SSO) tools.</p>
                    </div>
                </div>
                {/* <div className='w-full flex justify-center pb-10'><a href="#form" className='text-orange text-center'>Get our Checklist for Employee Rewards and Recognition now</a></div> */}
            </div>
        </section>  
        <section className='bg-indigo-100 w-full flex justify-center py-10 px-5'>
            <div className='max-w-7xl xl:flex'>
                <div className=''>
                    <h2 className='text-3xl xl:text-5xl text-center xl:text-left text-purple-100 font-bold pt-5 mb-8 md:px-12 xl:px-0 xl:w-9/12'>Vantage Rewards helped <span className='text-orange'>700+</span> global organizations</h2>
                    <p className='text-purple-100 xl:w-8/12 text-center xl:text-left'>See how organizations are using Vantage Circle to build an engaged and productive workplace</p>
                    <div class="flex justify-center items-center lg:justify-start w-5/6 mx-auto xl:mx-0 md:w-1/2 lg:w-full xl:w-4/12 cursor-pointer py-5">
                        <a class="vc-new-white-border-btn-rounded-full lato rounded-full w-full" href="#form">Get started</a>
                    </div>
                </div>
                <div className='xl:w-7/12 my-5'>
                    <a href= "/case-study/lti/"><div className='bg-white rounded flex px-7 py-5 w-full my-5' data-aos="fade-up" data-aos-duration="600" data-aos-delay= "300">
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-2-1.png" alt="Infosys Logo" width= "70" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>L&T Infotech increased its appreciation by 10.3x</p>
                    </div></a>
                    <a href= "/case-study/acg/"><div className='bg-white rounded flex px-7 py-5 w-full my-5' data-aos="fade-up" data-aos-duration="600" data-aos-delay= "500">
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-1.png" alt="Infosys Logo" width= "80" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>ACG saw a 66% rise in Peer-to-Peer Recognition</p>
                    </div></a>
                    <a href= "/case-study/tata-realty/"><div className='bg-white rounded flex px-7 py-5 w-full my-5' data-aos="fade-up" data-aos-duration="600" data-aos-delay= "500">
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-3-1.png" alt="Infosys Logo" width= "100" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>Tata Realty saw 40% growth in employee engagement</p>
                    </div></a>
                </div>
            </div>
        </section>   
        <section className='w-full flex flex-col justify-center items-center px-5 py-10'>
            <div className='max-w-7xl my-5'>
                <h2 className='text-gray-250 text-3xl xl:text-5xl xl:max-w-4xl mx-auto xl:mt-10 mb-5 font-bold text-center'>Winner of <span className= "text-orange">top global awards</span> in Employee Recognition and Engagement</h2>
                <div className='grid md:grid-cols-2 xl:grid-cols-3 py-5 gap-2 items-center justify-center'>
                    <div className='flex justify-center my-5'><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2023/02/G2-1.webp" alt="G2 award4" width="130" height="125" /></div>
                    <div className='flex justify-center my-5'><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664738/gatsbycms/uploads/2023/02/Bakers-Dozen-1.webp" alt="Baker's Dozen award" width="190" height="100" /></div>
                    <div className='flex justify-center my-5'><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548690/gatsbycms/uploads/2023/02/Brandon-Hall-1.webp" alt="Brandon hall award" width="190" height="110" /></div>
                </div>
            </div>



            {/* <div class="my-16" id="video">
                <h2 class="section-title text-center pb-10">Product video</h2> 
                <div class="container">              
                    <div className='amazonpaidvideo rounded-md shadow'>
                        <iframe className='youtube-videos rounded-md shadow' loading="lazy" width="560" height="500" src="https://www.youtube.com/embed/lMrM3gOJcyQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
                    </div>
                </div>
                <div className='flex place-content-center w-5/6 md:w-1/3 xl:w-3/12 mx-auto mt-12'><a class="vc-new-orange-btn-rounded-full lato mb-3 mx-auto" href="#form">Get started</a></div>
            </div> */}



            {/* <div className='w-full my-5'>
                <h2 className='text-gray-250 text-3xl xl:text-5xl xl:max-w-7xl mx-auto mt-10 mb-5 font-bold text-center'>Building culture of recognition at <span className='text-orange'>700+</span> Global Companies</h2>
                <div className='grid md:grid-cols-2 xl:grid-cols-4 py-5 gap-5 items-center justify-center my-9'>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "300"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/TA_Digital-logo.png" alt="TA_Digital Logo" width="140" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "400"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/piramal.png" alt="Parimal Logo" width="140" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "700"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/wipro.png" alt="Wipro Logo" width="100" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "1000"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/landmark.png" alt="Landmark Logo" width="140" /></div>
                </div>
                <div class="c-logo-strip w-full py-10 my-8 md:my-10 lg:my-16 2xl:my-20"></div>
                <a href="/corporates/" class="text-orange font-bold flex flex justify-center text-xl items-center mb-6 ">See more clients <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg></a>
            </div> */}
        </section>
    </Layout>
  )
}

export default AmazonPaidPage